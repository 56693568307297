import React, { useEffect, useState } from 'react';
import { LanguageIcon, RDSIconTextButton } from '@reconlabs/reconlabs-fe-components';
import { STATIC_FILES_URL } from '../constants/url';



const Error = () => {
  /*----------------------------------------
                      Data
   ----------------------------------------*/
  const [locale, setLocale] = useState<'ko' | 'en'>('ko');
  useEffect(() => {
    // 디폴트 locale을 브라우저 언어로 설정합니다. ko가 아닐 시 무조건 en
    if (window.navigator.language.includes('ko')) {
      setLocale('ko');
    } else {
      setLocale('en');
    }
  }, []);

  const changeLanguage = () => {
    if (locale === 'ko') {
      setLocale('en');
    } else {
      setLocale('ko');
    }
  };

  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <div className="ErrorPage">
      <div className="ErrorPage__info">
        <RDSIconTextButton
          icon={<LanguageIcon />}
          onClick={changeLanguage}
          type="outline"
          size="xsmall"
          customStyle={{ marginBottom: 12, userSelect: 'none' }}
        >
          {locale === 'ko' ? '한국어' : 'English'}
        </RDSIconTextButton>
        <div className="ErrorPage__info__title">
          {locale === 'ko' ? '페이지가 중단되었습니다.' : 'An error has occurred.'}
        </div>
        <div className="ErrorPage__info__content">
          {locale === 'ko'
            ? '관리자에 의해 중단된 페이지입니다.\n다시 페이지를 보고 싶으시다면 링크를 전달받은 관계자와 논의해주세요.'
            : 'The page was unpublished.\nPlease ask an administrator to access the content again.'}
        </div>
      </div>
      <img src={`${STATIC_FILES_URL}/images/zero-max-viewcount.png`} className="ErrorPage__img" />
    </div>
  );
};

export default Error;
