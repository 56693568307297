import { useQuery } from 'react-query';
import { axiosInstance } from './axios';

async function getCatalog(catalogUid: string): Promise<any> {
  const { data } = await axiosInstance.get('/public/catalog', {
    params: {
      catalog_uid: catalogUid,
    },
  });
  return data;
}

export function useCatalog(catalogUid: string) {
  const { data, error, isLoading } = useQuery('catalog', () => getCatalog(catalogUid));
  return { data, error, isLoading };
}
