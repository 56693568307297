import React, { useEffect, useRef, useState } from 'react';
import CatalogModelCard from './CatalogModelCard';
import { RDSModal, RDSPagination, RDSToast } from '@reconlabs/reconlabs-fe-components';
import { useCatalog } from '../hooks/react-query/useCatalog';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import amplitude from '../utils/amplitude';
import amplitudeEvents from '../constants/amplitudeEvents';

const Catalog = () => {
  /*----------------------------------------
                      Data
   ----------------------------------------*/
  const location = useLocation();
  const navigate = useNavigate();
  const catalogUid = location.pathname.slice(location.pathname.lastIndexOf('/') + 1);
  const { data, error, isLoading } = useCatalog(catalogUid);
  const [catalogName, setCatalogName] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [catalogItems, setCatalogItems] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentItems, setCurrentItems] = useState<any[]>([]);
  const [unavailableModal, setUnavailableModal] = useState<boolean>(false);
  const [unavailableModalEn, setUnavailableModalEn] = useState<boolean>(false);
  const [locale, setLocale] = useState<string>('ko');
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const isPageViewAmplitudeSent = useRef<boolean>(false);
  const [showSalesUrl, setShowSalesUrl] = useState<boolean>(false);
  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/

  useEffect(() => {
    if (data && data.result === 'success') {
      setCatalogName(data.catalog_name);
      setCompanyName(data.catalog_metadata.company_name);
      setCatalogItems(data.catalog_items.data);
      setLocale(data.catalog_lang);
      setShowSalesUrl(data.catalog_show_sales_url == 1);

      if (isPageViewAmplitudeSent.current === false) {
        // Amplitude: 카탈로그 데이터 요청 성공 시 page view 이벤트 전송
        amplitude.sendEvent(amplitudeEvents.readonly.zero_readonly, {
          page_url_full: window.location.href,
          page_referrer_full: !document.referrer ? 'null' : document.referrer,
          company: data.catalog_metadata.company_name,
          product_quantity: data.catalog_items.data.length,
        });
        isPageViewAmplitudeSent.current = true;
      }
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      const axiosErr = error as AxiosError<any>;
      if (axiosErr.response) {
        if (axiosErr.response.data?.result === 'exceeded_max_view_count') {
          // Amplitude: max_viewcount 초과 시 에러 이벤트 전송
          amplitude.sendEvent(amplitudeEvents.readonly.zero_catalog_readonly_error);
          navigate('/error');
        }
      }
    }
  }, [error]);

  useEffect(() => {
    if (catalogItems && catalogItems.length > 0) {
      const startIndex = (currentPage - 1) * 6;
      const endIndex = startIndex + 6;
      setCurrentItems(catalogItems.slice(startIndex, endIndex));
    }
  }, [catalogItems, currentPage]);

  // 페이지네이션 변경 시 최상단으로 스크롤
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentPage]);

  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <div className="Catalog">
      {isLoading && (
        <RDSToast.Overlay
          type="loadingSpinner"
          toastOverlayId="Catalog__loading"
          openedToastOverlay="Catalog__loading"
          setOpenedToastOverlay={() => {}}
        />
      )}
      <div className="Catalog__Container">
        <div className="Catalog__Container__Header">
          <div className="Catalog__Container__Header__Title">{catalogName}</div>
          <div className="Catalog__Container__Header__Sub">{companyName}</div>
        </div>
        <div className="Catalog__Container__Contents">
          {currentItems && currentItems.length > 0
            ? currentItems.map((item: any) => {
                return (
                  <CatalogModelCard
                    key={item.viewer_uid}
                    item={item}
                    setModal={locale === 'ko' ? setUnavailableModal : setUnavailableModalEn}
                    locale={locale}
                    showSalesUrl={showSalesUrl}
                  />
                );
              })
            : ''}
        </div>
        <div className="Catalog__Container__Pagination">
          {data && data.catalog_items ? (
            <RDSPagination.Number
              size="small"
              pageSize={6}
              minimumType={isMobile ? true : false}
              total={data.catalog_items.count}
              current={currentPage}
              handleChange={(page) => {
                amplitude.sendEvent(amplitudeEvents.readonly.zero_catalog_readonly_pagination);
                setCurrentPage(page);
              }}
            />
          ) : (
            ''
          )}
        </div>
        <hr className="Catalog__Container__Hr" />
        <div className="Catalog__Container__Footer">Powered by PlicAR</div>
      </div>
      <RDSModal
        open={unavailableModal}
        onClose={() => setUnavailableModal(false)}
        title="현재 이용할 수 없는 모델입니다."
        supportingText="관리자 요청에 의해 AR 뷰어 공유가 중지된 상태입니다."
        buttonType="noBox"
        button1Label="확인"
        button1Fn={() => setUnavailableModal(false)}
        enableCloseButton={false}
      />
      <RDSModal
        open={unavailableModalEn}
        onClose={() => setUnavailableModalEn(false)}
        title="The model is not available."
        supportingText="The AR viewer has been disabled due to administrator request."
        buttonType="noBox"
        button1Label="OK"
        button1Fn={() => setUnavailableModalEn(false)}
        enableCloseButton={false}
      />
    </div>
  );
};

export default Catalog;
