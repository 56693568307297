const readonly = {
  // Page View Events
  zero_readonly: 'zero_readonly',
  zero_catalog_readonly_error: 'zero_catalog_readonly_error', // error 페이지로 navigate 시

  // User Events
  zero_catalog_readonly_3dviewer_enlarge: 'zero_catalog_readonly_3dviewer_enlarge',
  zero_catalog_readonly_3dviewer_turn: 'zero_catalog_readonly_3dviewer_turn',
  zero_catalog_readonly_arviewer: 'zero_catalog_readonly_arviewer',
  zero_catalog_readonly_pagination: 'zero_catalog_readonly_pagination',
  zero_catalog_readonly_3dviewer_ratio: 'zero_catalog_readonly_3dviewer_ratio',
  zero_catalog_readonly_3dviewer_settings_lighting: 'zero_catalog_readonly_3dviewer_settings_lighting',
  zero_catalog_readonly_3dviewer_settings_size: 'zero_catalog_readonly_3dviewer_settings_size',
  zero_catalog_readonly_3dviewer_guide: 'zero_catalog_readonly_3dviewer_guide',
};

export default { readonly };
