import React from 'react';
import Catalog from './components/Catalog';
import { QueryClientProvider } from 'react-query';
import { Route, Routes } from 'react-router-dom';
import { queryClient } from './hooks/react-query/queryClient';
import Error from './components/Error';

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="/*" element={<Catalog />}></Route>
        <Route path="/error" element={<Error />}></Route>
      </Routes>
    </QueryClientProvider>
  );
};

export default App;
