import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  RDSIconButton,
  XMarkIcon,
  RDSDropdown,
  RDSOption,
  RDSIconTextButton,
  AlertErrorIcon,
  ImageAspectRatioIcon,
  useThrottle,
} from '@reconlabs/reconlabs-fe-components';
import { getModelViewerScriptSource, STATIC_FILES_URL } from '../constants/url';
import GuideImage from './svgs/GuideImage';
import GuideImageEn from './svgs/GuideImageEn';
import GuideImage2 from './svgs/GuideImage2';
import GuideImage2En from './svgs/GuideImage2En';
import GuideImage3 from './svgs/GuideImage3';
import GuideImage3En from './svgs/GuideImage3En';
import amplitude from '../utils/amplitude';
import amplitudeEvents from '../constants/amplitudeEvents';

type CatalogMobileModelViewerProps = {
  viewerUid: string;
  openModal: boolean;
  setOpenModal: Function;
  locale: string;
};

type ModelViewerSize = {
  x: number;
  y: number;
  z: number;
  toString: Function;
};

const hdrImage = {
  first: `${STATIC_FILES_URL}/images/hdr/plicar-studio.hdr`,
  second: `${STATIC_FILES_URL}/images/hdr/plicar-studio2.hdr`,
  studio: `${STATIC_FILES_URL}/images/hdr/hdr-studio.hdr`,
  neutral: `${STATIC_FILES_URL}/images/hdr/hdr-neutral.hdr`,
  outdoor: `${STATIC_FILES_URL}/images/hdr/hdr-outdoor.hdr`,
  indoor: `${STATIC_FILES_URL}/images/hdr/hdr-indoor.hdr`,
};

const CatalogModelModal = (props: CatalogMobileModelViewerProps) => {
  const isMobile = useMediaQuery({ maxWidth: 960 });
  /*----------------------------------------
                      Data
   ----------------------------------------*/
  const locale = props.locale;
  const [lightingMode, setLightingMode] = useState<'default' | 'studio' | 'neutral' | 'outdoor' | 'indoor' | 'hdr'>(
    'default',
  );
  const [measureMode, setMeasureMode] = useState<'none' | 'mm' | 'cm' | 'inch'>('none');
  const [size, setSize] = useState<ModelViewerSize>();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [isSquare, setIsSquare] = useState(false);
  const [pcHeight, setPcHeight] = useState<number>(0);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const environmentImage = useMemo(() => {
    switch (lightingMode) {
      case 'default':
        return 'neutral';
      case 'indoor':
        return hdrImage.indoor;
      case 'neutral':
        return hdrImage.neutral;
      case 'outdoor':
        return hdrImage.outdoor;
      case 'studio':
        return hdrImage.studio;
      case 'hdr':
        return hdrImage.second;
      default:
        return 'neutral';
    }
  }, [lightingMode]);
  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    if (document.querySelector('.plicarzero-viewer') === null) {
      const RDSPlicarViewerScript = document.createElement('script');
      RDSPlicarViewerScript.classList.add('plicarzero-viewer');
      RDSPlicarViewerScript.type = 'module';
      RDSPlicarViewerScript.src = getModelViewerScriptSource(); // TODO API Endpoint 변경 및 안정화시 plicar-viewer.min.js 로 변경
      document.head.appendChild(RDSPlicarViewerScript);
    }
  }, []);

  // measure indicator 세팅
  useEffect(() => {
    const modelViewer = document.querySelector('#CatalogModelModal__PlicarViewer');
    if (modelViewer !== null) {
      modelViewer.addEventListener('load', () => {
        //@ts-ignore
        const center = modelViewer.getBoundingBoxCenter();

        // const center = modelViewer.getCameraTarget();
        //@ts-ignore
        const size = modelViewer.getDimensions();
        setSize(size);

        const x2 = size.x / 2;
        const y2 = size.y / 2;
        const z2 = size.z / 2;

        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dot+X-Y+Z',
          position: `${center.x + x2} ${center.y - y2} ${center.z + z2}`,
        });
        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dim+X-Y',
          position: `${center.x + x2} ${center.y - y2} ${center.z}`,
        });
        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dot+X-Y-Z',
          position: `${center.x + x2} ${center.y - y2} ${center.z - z2}`,
        });
        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dim+X-Z',
          position: `${center.x + x2} ${center.y} ${center.z - z2}`,
        });
        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dot+X+Y-Z',
          position: `${center.x + x2} ${center.y + y2} ${center.z - z2}`,
        });
        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dim+Y-Z',
          position: `${center.x} ${center.y + y2} ${center.z - z2}`,
        });
        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dot-X+Y-Z',
          position: `${center.x - x2} ${center.y + y2} ${center.z - z2}`,
        });
        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dim-X-Z',
          position: `${center.x - x2} ${center.y} ${center.z - z2}`,
        });
        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dot-X-Y-Z',
          position: `${center.x - x2} ${center.y - y2} ${center.z - z2}`,
        });
        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dim-X-Y',
          position: `${center.x - x2} ${center.y - y2} ${center.z}`,
        });
        //@ts-ignore
        modelViewer.updateHotspot({
          name: 'hotspot-dot-X-Y+Z',
          position: `${center.x - x2} ${center.y - y2} ${center.z + z2}`,
        });
        // update svg
        function drawLine(
          svgLine: SVGLineElement,
          dotHotspot1: any,
          dotHotspot2: any,
          dimensionHotspot: { facingCamera: any } | undefined,
        ) {
          if (svgLine == null) {
            return;
          }
          if (dotHotspot1 && dotHotspot1) {
            svgLine.setAttribute('x1', dotHotspot1.screenPosition.x);
            svgLine.setAttribute('y1', dotHotspot1.screenPosition.y);
            svgLine.setAttribute('x2', dotHotspot2.screenPosition.x);
            svgLine.setAttribute('y2', dotHotspot2.screenPosition.y);

            // use provided optional hotspot to tie visibility of this svg line to
            if (dimensionHotspot && !dimensionHotspot.facingCamera) {
              svgLine.classList.add('RDSPlicarViewer__hide');
            } else {
              svgLine.classList.remove('RDSPlicarViewer__hide');
            }
          }
        }

        const lines = modelViewer.querySelectorAll('line');

        // use requestAnimationFrame to update with renderer
        const startSVGRenderLoop = () => {
          drawLine(
            lines[0],
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dot+X-Y+Z'),
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dot+X-Y-Z'),
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dim+X-Y'),
          );
          drawLine(
            lines[1],
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dot+X-Y-Z'),
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dot+X+Y-Z'),
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dim+X-Z'),
          );
          // @ts-ignore
          drawLine(
            lines[2],
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dot+X+Y-Z'),
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dot-X+Y-Z'),
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dim+Y-Z'),
          ); // always visible
          drawLine(
            lines[3],
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dot-X+Y-Z'),
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dot-X-Y-Z'),
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dim-X-Z'),
          );
          drawLine(
            lines[4],
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dot-X-Y-Z'),
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dot-X-Y+Z'),
            // @ts-ignore
            modelViewer.queryHotspot('hotspot-dim-X-Y'),
          );
          requestAnimationFrame(startSVGRenderLoop);
        };

        startSVGRenderLoop();
      });
    }
  }, []);

  const setViewerHeight = () => {
    if (wrapperRef.current) {
      setPcHeight(wrapperRef.current.clientHeight);
    }
  };

  const throttleOnScroll = useThrottle(setViewerHeight, 300);

  useEffect(() => {
    setViewerHeight();
    window.addEventListener('resize', throttleOnScroll);
    return () => {
      window.removeEventListener('resize', throttleOnScroll);
    };
  }, []);

  /*----------------------------------------
                Business Logic
   ----------------------------------------*/
  const convertMeter = (meter: number | any, resultLength: 'mm' | 'cm' | 'inch' | 'none') => {
    if (isNaN(meter) || typeof meter !== 'number') {
      return 0;
    }

    if (resultLength === 'cm') {
      return meter * 100;
    } else if (resultLength === 'inch') {
      return meter * 39.3701;
    } else if (resultLength === 'mm') {
      return meter * 1000;
    } else {
      return 0;
    }
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/

  return (
    <div
      className="CatalogModelModal__backdrop"
      hidden={!props.openModal}
      onClick={(e) => {
        props.setOpenModal(false);
      }}
      style={{ pointerEvents: 'auto' }}
    >
      <div className="CatalogModelModal" onClick={(e) => e.stopPropagation()}>
        <div className="CatalogModelModal__Header">
          <div className="CatalogModelModal__Title">
            {locale === 'ko' ? '플리카' : 'PlicAR'} <span className="CatalogModelModal__Title__3D">3D</span> Viewer
          </div>
          <div className="CatalogModelModal__Close">
            <RDSIconButton
              type="noBox"
              size="small"
              icon={<XMarkIcon />}
              customStyle={{ color: 'white' }}
              onClick={() => props.setOpenModal(false)}
            />
          </div>
        </div>

        <div className="CatalogModelModal__Contents">
          <div className="CatalogModelModal__Contents__Controls">
            <div className="CatalogModelModal__Contents__Controls__Title">
              {locale === 'ko' ? '3D 환경을 설정해보세요!' : 'Set Your 3D Viewer to Your Preference!'}
            </div>
            <RDSDropdown
              width={'100%'}
              onChange={(value) => {
                amplitude.sendEvent(amplitudeEvents.readonly.zero_catalog_readonly_3dviewer_settings_lighting, {
                  type: value,
                });
                setLightingMode(value);
              }}
              value={lightingMode}
              customStyles={{ marginTop: '16px', zIndex: 200, pointerEvents: 'auto' }}
            >
              <React.Fragment key=".0">
                <RDSOption value="default">{locale === 'ko' ? '기본 조명' : 'Default Lights'}</RDSOption>
                <RDSOption value="studio">{locale === 'ko' ? '스튜디오 조명' : 'Studio Lights'}</RDSOption>
                <RDSOption value="neutral">{locale === 'ko' ? '무색조명' : 'Neutral Lights'}</RDSOption>
                <RDSOption value="indoor">{locale === 'ko' ? '실내조명' : 'Indoor Lights'}</RDSOption>
                <RDSOption value="outdoor">{locale === 'ko' ? '실외조명' : 'Outdoor Lights'}</RDSOption>
              </React.Fragment>
            </RDSDropdown>
            <RDSDropdown
              width={'100%'}
              onChange={(value) => {
                amplitude.sendEvent(amplitudeEvents.readonly.zero_catalog_readonly_3dviewer_settings_size, {
                  type: value === 'none' ? 'hide' : value,
                });
                setMeasureMode(value);
              }}
              value={measureMode}
              customStyles={{ marginTop: '16px', pointerEvents: 'auto' }}
            >
              <React.Fragment key=".0">
                <RDSOption value="none">{locale === 'ko' ? '치수 숨김' : 'Hide Measurements'}</RDSOption>
                <RDSOption value="mm">{locale === 'ko' ? '밀리미터' : 'Millimeter'} (mm)</RDSOption>
                <RDSOption value="cm">{locale === 'ko' ? '센치미터' : 'Centimeter'} (cm)</RDSOption>
                <RDSOption value="inch">{locale === 'ko' ? '인치' : 'Inch'} (inch)</RDSOption>
              </React.Fragment>
            </RDSDropdown>

            <div className="CatalogModelModal__Contents__Controls__Button">
              <RDSIconTextButton
                icon={<AlertErrorIcon />}
                size={'small'}
                color={'primary-light'}
                customStyle={{ width: '198px', height: '50px', pointerEvents: 'auto' }}
                onClick={() => {
                  if (!openModal) amplitude.sendEvent(amplitudeEvents.readonly.zero_catalog_readonly_3dviewer_guide);
                  setOpenModal((prev) => !prev);
                }}
              >
                {locale === 'ko' ? '3D viewer 확인 방법' : '3D Viewer User Guide'}
              </RDSIconTextButton>
            </div>
          </div>

          <div
            className="rdsfont"
            ref={wrapperRef}
            style={
              isMobile
                ? {}
                : {
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    paddingBottom: '40%',
                    position: 'relative',
                    transition: 'all 150ms linear',
                    borderRadius: '4px',
                    overflow: 'hidden',
                    zIndex: 1,
                  }
            }
          >
            <div
              className="CatalogModelModal__Paper"
              style={
                isMobile
                  ? {}
                  : {
                      position: 'absolute',
                      top: 0,
                      left: isSquare ? `calc((100% - ${pcHeight}px) / 2)` : '0',
                      zIndex: 1,
                      width: isSquare ? pcHeight : '100%',
                      height: '100%',
                      transition: 'all 150ms linear',
                      borderRadius: '4px',
                      overflow: 'hidden',
                      maxHeight: '100%',
                    }
              }
            >
              {/* @ts-ignore */}
              <plicarzero-viewer
                id="CatalogModelModal__PlicarViewer"
                class="CatalogModelModal__Paper__Viewer"
                style={{ width: '100%', height: '100%', borderRadius: '8px', backgroundColor: 'white' }}
                viewer-uid={props.viewerUid}
                viewer-data={''}
                exposure="1"
                environment-image={environmentImage}
                camera-controls
                camera-orbit="25deg 75deg 105%"
                max-camera-orbit="Infinity 170deg 120%"
                min-camera-orbit="-Infinity 10deg auto"
                touch-action="auto"
              >
                {measureMode && (
                  <>
                    <button
                      slot="hotspot-dot+X-Y+Z"
                      className={`RDSPlicarViewer__dot ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                      data-position="1 -1 1"
                      data-normal="1 0 0"
                    ></button>
                    <button
                      slot="hotspot-dim+X-Y"
                      className={`RDSPlicarViewer__dim ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                      data-position="1 -1 0"
                      data-normal="1 0 0"
                    >
                      {size ? convertMeter(size.z, measureMode).toFixed(0) + ' ' + measureMode : ''}
                    </button>
                    <button
                      slot="hotspot-dot+X-Y-Z"
                      className={`RDSPlicarViewer__dot ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                      data-position="1 -1 -1"
                      data-normal="1 0 0"
                    ></button>
                    <button
                      slot="hotspot-dim+X-Z"
                      className={`RDSPlicarViewer__dim ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                      data-position="1 0 -1"
                      data-normal="1 0 0"
                    >
                      {size ? convertMeter(size.y, measureMode).toFixed(0) + ' ' + measureMode : ''}
                    </button>
                    <button
                      slot="hotspot-dot+X+Y-Z"
                      className={`RDSPlicarViewer__dot ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                      data-position="1 1 -1"
                      data-normal="0 1 0"
                    ></button>
                    <button
                      slot="hotspot-dim+Y-Z"
                      className={`RDSPlicarViewer__dim ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                      data-position="0 -1 -1"
                      data-normal="0 1 0"
                    >
                      {size ? convertMeter(size.x, measureMode).toFixed(0) + ' ' + measureMode : ''}
                    </button>
                    <button
                      slot="hotspot-dot-X+Y-Z"
                      className={`RDSPlicarViewer__dot ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                      data-position="-1 1 -1"
                      data-normal="0 1 0"
                    ></button>
                    <button
                      slot="hotspot-dim-X-Z"
                      className={`RDSPlicarViewer__dim ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                      data-position="-1 0 -1"
                      data-normal="-1 0 0"
                    >
                      {size ? convertMeter(size.y, measureMode).toFixed(0) + ' ' + measureMode : ''}
                    </button>
                    <button
                      slot="hotspot-dot-X-Y-Z"
                      className={`RDSPlicarViewer__dot ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                      data-position="-1 -1 -1"
                      data-normal="-1 0 0"
                    ></button>
                    <button
                      slot="hotspot-dim-X-Y"
                      className={`RDSPlicarViewer__dim ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                      data-position="-1 -1 0"
                      data-normal="-1 0 0"
                    >
                      {size ? convertMeter(size.z, measureMode).toFixed(0) + ' ' + measureMode : ''}
                    </button>
                    <button
                      slot="hotspot-dot-X-Y+Z"
                      className={`RDSPlicarViewer__dot ${measureMode === 'none' && 'RDSPlicarViewer__hide'}`}
                      data-position="-1 -1 1"
                      data-normal="-1 0 0"
                    ></button>
                    <svg
                      id="lines"
                      xmlns="http://www.w3.org/2000/svg"
                      className="RDSPlicarViewer__dimensionLineContainer"
                    >
                      <line
                        className={` ${
                          measureMode === 'none' ? 'RDSPlicarViewer__hide' : 'RDSPlicarViewer__dimensionLine'
                        }`}
                      ></line>
                      <line
                        className={` ${
                          measureMode === 'none' ? 'RDSPlicarViewer__hide' : 'RDSPlicarViewer__dimensionLine'
                        }`}
                      ></line>
                      <line
                        className={` ${
                          measureMode === 'none' ? 'RDSPlicarViewer__hide' : 'RDSPlicarViewer__dimensionLine'
                        }`}
                      ></line>
                      <line
                        className={` ${
                          measureMode === 'none' ? 'RDSPlicarViewer__hide' : 'RDSPlicarViewer__dimensionLine'
                        }`}
                      ></line>
                      <line
                        className={` ${
                          measureMode === 'none' ? 'RDSPlicarViewer__hide' : 'RDSPlicarViewer__dimensionLine'
                        }`}
                      ></line>
                    </svg>
                  </>
                )}
                {/* @ts-ignore */}
              </plicarzero-viewer>

              <RDSIconButton
                icon={<ImageAspectRatioIcon />}
                type="noBox"
                size="large"
                color="grey"
                customStyle={{
                  display: isMobile ? 'none' : 'block',
                  position: 'absolute',
                  top: 12,
                  right: 9,
                  zIndex: 2,
                  pointerEvents: 'auto',
                }}
                onClick={() => {
                  amplitude.sendEvent(amplitudeEvents.readonly.zero_catalog_readonly_3dviewer_ratio, {
                    type: isSquare ? 'rectangle' : 'square',
                  });
                  setIsSquare(!isSquare);
                }}
              />

              <div hidden={openModal ? false : true}>
                <div className="GuideModal">
                  {props.locale === 'ko' ? (
                    <>
                      <GuideImage />
                      <GuideImage2 />
                      <GuideImage3 />
                    </>
                  ) : (
                    <>
                      <GuideImageEn />
                      <GuideImage2En />
                      <GuideImage3En />
                    </>
                  )}
                </div>
                <div className="GuideModal__CloseButton">
                  <RDSIconButton
                    type="noBox"
                    icon={<XMarkIcon />}
                    customStyle={{ color: 'white' }}
                    onClick={() => setOpenModal(false)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CatalogModelModal;
