import React, { useEffect, useRef, useState } from 'react';
import { RDSIconButton, RDSIconTextButton, ThreeDIcon } from '@reconlabs/reconlabs-fe-components';
import FullScreenIcon from './svgs/FullScreenIcon';
import { getModelViewerScriptSource, getPlicarzeroButtonScriptSource } from '../constants/url';
import CatalogModelModal from './CatalogModelModal';
import { CatalogItemType } from '../types';
import amplitude from '../utils/amplitude';
import amplitudeEvents from '../constants/amplitudeEvents';

type CatalogModelCardProps = {
  item: CatalogItemType;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  locale: string;
  showSalesUrl: boolean;
};

const PurchaseIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.457 9.83464C13.082 9.83464 13.632 9.49297 13.9154 8.9763L16.8987 3.56797C17.207 3.01797 16.807 2.33464 16.1737 2.33464H3.84036L3.05703 0.667969H0.332031V2.33464H1.9987L4.9987 8.65964L3.8737 10.693C3.26536 11.8096 4.06536 13.168 5.33203 13.168H15.332V11.5013H5.33203L6.2487 9.83464H12.457ZM4.63203 4.0013H14.757L12.457 8.16797H6.60703L4.63203 4.0013ZM5.33203 14.0013C4.41536 14.0013 3.6737 14.7513 3.6737 15.668C3.6737 16.5846 4.41536 17.3346 5.33203 17.3346C6.2487 17.3346 6.9987 16.5846 6.9987 15.668C6.9987 14.7513 6.2487 14.0013 5.33203 14.0013ZM13.6654 14.0013C12.7487 14.0013 12.007 14.7513 12.007 15.668C12.007 16.5846 12.7487 17.3346 13.6654 17.3346C14.582 17.3346 15.332 16.5846 15.332 15.668C15.332 14.7513 14.582 14.0013 13.6654 14.0013Z"
      fill="#0C0D0D"
    />
  </svg>
);

const CatalogModelCard = (props: CatalogModelCardProps) => {
  /*----------------------------------------
                      Data
   ----------------------------------------*/
  const modelName = props.item.name;
  const subText = props.item.description;
  const viewerUid = props.item.viewer_uid;
  const salesUrl = props.item.sales_url;
  const showSalesUrl = props.showSalesUrl;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const plicarBtnRef = useRef<HTMLElement | null>(null);
  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    if (document.querySelector('.plicarzero-viewer') === null) {
      const RDSPlicarViewerScript = document.createElement('script');
      RDSPlicarViewerScript.classList.add('plicarzero-viewer');
      RDSPlicarViewerScript.type = 'module';
      RDSPlicarViewerScript.src = getModelViewerScriptSource(); // TODO API Endpoint 변경 및 안정화시 plicar-viewer.min.js 로 변경
      document.head.appendChild(RDSPlicarViewerScript);
    }
  }, []);

  useEffect(() => {
    if (document.querySelector('.plicarzero-button-script') === null) {
      const plicarzeroScript = document.createElement('script');
      plicarzeroScript.classList.add('plicarzero-button-script');
      plicarzeroScript.type = 'module';
      plicarzeroScript.src = getPlicarzeroButtonScriptSource();
      document.head.appendChild(plicarzeroScript);
    }
  }, []);

  // modal open 시 뒤 스크롤 방지 (PC)
  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [openModal]);

  const addProtocolInUrl = (link: string) => {
    if (link !== '' && link !== undefined && link !== null && !link.includes('https') && !link.includes('http')) {
      link = 'https://' + link;
    }
    return link;
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <>
      {openModal && (
        <CatalogModelModal
          openModal={openModal}
          viewerUid={props.item.viewer_uid}
          setOpenModal={setOpenModal}
          locale={props.locale}
        />
      )}
      <div className="CatalogModelCard">
        <div>
          <div
            className="CatalogModelCard__Viewer"
            onMouseUp={() => {
              amplitude.sendEvent(amplitudeEvents.readonly.zero_catalog_readonly_3dviewer_turn);
            }}
            onTouchEnd={(e) => {
              amplitude.sendEvent(amplitudeEvents.readonly.zero_catalog_readonly_3dviewer_turn);
              e.preventDefault();
            }}
          >
            <div
              className="CatalogModelCard__Viewer__Icon"
              // 확장 아이콘 클릭 시 상위의 뷰어 조작 이벤트 mouseup, touchend가 동작하지 않게 하기 위한 stopPropagation입니다.
              onMouseUp={(e) => {
                e.stopPropagation();
              }}
              onTouchEnd={(e) => {
                e.stopPropagation();
              }}
            >
              <RDSIconButton
                icon={<FullScreenIcon />}
                type={'noBox'}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  amplitude.sendEvent(amplitudeEvents.readonly.zero_catalog_readonly_3dviewer_enlarge);
                  setOpenModal(true);
                }}
              />
            </div>
            {/* @ts-ignore */}
            <plicarzero-viewer
              data-testid="plicarzero-viewer"
              style={{ width: '100%', height: '100%' }}
              // viewer-uid={props.item.viewer_uid}
              viewer-uid={viewerUid}
              viewer-data={''}
              exposure="1"
              environment-image={'neutral'}
              camera-controls
              camera-orbit="25deg 75deg 105%"
              max-camera-orbit="Infinity 170deg 120%"
              min-camera-orbit="-Infinity 10deg auto"
              language={props.locale}
            />
          </div>
          <div className="CatalogModelCard__Info">
            <div className="CatalogModelCard__Info__Title">{modelName}</div>
            <div className="CatalogModelCard__Info__Text">{subText}</div>
          </div>
        </div>
        <div>
          <button
            className="CatalogModelCard__Button"
            onClick={() => {
              const button = plicarBtnRef.current?.shadowRoot?.querySelector('button');

              if (button?.classList.contains('disabled')) {
                props.setModal(true);
              } else {
                amplitude.sendEvent(amplitudeEvents.readonly.zero_catalog_readonly_arviewer);
                button?.click();
              }
            }}
            style={{ marginBottom: showSalesUrl && !!salesUrl ? '' : '58px' }}
          >
            <ThreeDIcon color="inherit" width="20" height="20" />
            {props.locale === 'ko' ? 'AR로 제품 보기' : 'View in AR'}
            <span className="CatalogModelCard__Button__Hidden" onClick={(e) => e.stopPropagation()}>
              {/* @ts-ignore */}
              <plicarzero-button
                // class="CatalogModelCard__Button__Hidden"
                ref={plicarBtnRef}
                viewer-uid={viewerUid}
                color-type="primary"
                sales-url={showSalesUrl ? salesUrl : null}
                language={props.locale}
              />
            </span>
          </button>
          {showSalesUrl && !!salesUrl && (
            <RDSIconTextButton
              icon={<PurchaseIcon />}
              customStyle={{ marginTop: '8px' }}
              color="grey"
              fullWidth
              onClick={() => window.open(addProtocolInUrl(props.item.sales_url))}
            >
              {props.locale === 'ko' ? '구매하러 가기' : 'Go to Purchase'}
            </RDSIconTextButton>
          )}
        </div>
      </div>
    </>
  );
};

export default CatalogModelCard;
