import * as amplitude from '@amplitude/analytics-browser';

export type AmplitudeEvent = {
  name: string;
  props?: object | any;
};

class AmplitudeLoader {
  private _userId: string;
  private _userInfo: object;
  private _startTimeInMilliseconds: number | null;

  constructor() {
    this._userId = '';
    this._userInfo = {};
    this._startTimeInMilliseconds = null;
  }

  get userId() {
    return this._userId;
  }

  get userInfo() {
    return this._userInfo;
  }

  get startTimeInMilliseconds() {
    return this._startTimeInMilliseconds;
  }

  initialize(amplitudeKey: string, userId?: string, options?: amplitude.Types.BrowserOptions) {
    amplitude.init(amplitudeKey, userId, options);
    if (userId) this._userId = userId;
  }

  setUserId(userId: string) {
    amplitude.setUserId(userId);
    this._userId = userId;
  }

  setUserInfo(key: string, info: string) {
    const userInfo = new amplitude.Identify();
    userInfo.set(key, info);
    amplitude.identify(userInfo);

    this._userInfo = { ...this.userInfo, key: info };
  }

  sendEvent(eventName: string, eventProps?: object) {
    if (eventProps) {
      amplitude.track(eventName, eventProps);
    } else {
      amplitude.track(eventName);
    }
  }

  async sendAsyncEvent(eventName: string, eventProps?: object) {
    if (eventProps) {
      const result = await amplitude.track(eventName, eventProps).promise;
      return result;
    } else {
      const result = await amplitude.track(eventName).promise;
      return result;
    }
  }

  setStartTime(timeInMilliseconds: number) {
    this._startTimeInMilliseconds = timeInMilliseconds;
  }

  getDuration(timeInMilliseconds: number) {
    if (this._startTimeInMilliseconds !== null) {
      return timeInMilliseconds - this._startTimeInMilliseconds;
    }
  }

  reset() {
    amplitude.reset();
  }
}

export default new AmplitudeLoader();
