const PLICAR_ZERO_ARGATEWAY_URL = process.env.REACT_APP_PLICAR_ZERO_ARGATEWAY_URL;
export const STATIC_FILES_URL = `https://${process.env.REACT_APP_ASSET_DOMAIN}`;

export const getARGatewayUrl = (viewerUid: string) => {
  return `${PLICAR_ZERO_ARGATEWAY_URL}/${viewerUid}`;
};

export const getModelViewerScriptSource = () => {
  return `${STATIC_FILES_URL}/script/plicar-viewer.min.js`;
};

export const getPlicarzeroButtonScriptSource = () => {
  return `${STATIC_FILES_URL}/script/plicarzero-button.min.js`;
};
